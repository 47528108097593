import paths from './paths';

const navMenuConfig = [
    {
        label: 'Giới Thiệu',
        children: [
            {
                label: 'Banner',
                path: paths.bannerList
            },
            {
                label: 'Thông tin công ty',
                path: paths.informationList
            },
            {
                label: 'Giá trị cốt lõi',
                children: [
                    {
                        label: 'Thông điệp và tầm nhìn',
                        path: paths.visionList
                    },
                    {
                        label: 'Giá trị cốt lõi',
                        path: paths.coreValueList
                    }
                ]
            },
            {
                label: 'Nhân sự',
                path: paths.employeeList
            },
            {
                label: 'Lĩnh vực hoạt động',
                path: paths.businessAreaList
            },
            {
                label: 'Khách hàng',
                path: paths.partnerList
            },
        ]
    },
    {
        label: 'Dự án',
        path: paths.projectList
    },
    {
        label: 'Quản lý tin tức',
        path: paths.newsList
    },
    {
        label: 'Quản lý bình luận',
        path: paths.commentList
    },
    {
        label: 'Tài liệu',
        path: paths.documentList
    },
    {
        label: 'Ứng viên',
        path: paths.candidateList
    },
]

export default navMenuConfig;
