import { ListPageContainer } from '@/components';
import { newsActions } from '@/redux/actions';
import paths from '@/constants/paths';
import { formatDate } from '@/utils/date';

const ListPage = () => {

    return (
        <ListPageContainer
            disablePagination={true}
            objectName="tin tức"
            detailUrl={paths.newsDetail}
            createUrl={paths.newsCreate}
            breadcrumbs={[
                { breadcrumbName: 'Tin tức' },
                { breadcrumbName: 'Danh sách tin tức' }
            ]}
            columns={[
                { title: 'Id', dataIndex: 'id' },
                { title: 'Ảnh đại diện', dataIndex: 'thumbnailImage', render: (imageUrl) => <><img src={imageUrl} alt='imgBanner' style={{ width: '100px', height: '100px', objectFit: 'cover' }} /></> },
                { title: 'Tiêu đề', dataIndex: 'title' },
                { title: 'Trạng thái', dataIndex: 'status', render: (status) => <>{status === 'NEW' ? 'New' : status === 'APPROVED' ? 'Approved' : status === 'REJECTED' ? "Stop" : 'null'}</> },
                { title: 'Nguồn bài viết', dataIndex: 'source' },
                { title: 'Ngày hoạt động', dataIndex: 'dateCreated', render: (dateCreated) => <>{formatDate(dateCreated)}</> }
            ]}
            actionBar={{
                isEdit: true,
                isCreate: true,
                isDelete: true
            }}
            getListAction={newsActions.getList}
            deleteAction={newsActions.delete}
        >
        </ListPageContainer >

    )
}

export default ListPage;