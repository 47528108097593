import { ListPageContainer } from '@/components';
import { businessActions } from '@/redux/actions';
import paths from '@/constants/paths';

const ListPage = () => {

    return (
        <ListPageContainer
            disablePagination={true}
            objectName="lĩnh vực hoạt động"
            detailUrl={paths.businessAreaDetail}
            createUrl={paths.businessAreaCreate}
            breadcrumbs={[
                { breadcrumbName: 'Giới thiệu' },
                { breadcrumbName: 'Danh sách lĩnh vực hoạt động' }
            ]}
            columns={[
                { title: 'Id', dataIndex: 'id' },
                { title: 'Ảnh đại diện', dataIndex: 'image', render: (imageUrl) => <><img src={imageUrl} alt='imgBusinessArea' style={{ width: '250px', height: '500px', objectFit: 'contain' }} /></> },
                { title: 'Lĩnh vực', dataIndex: 'name' },
                { title: 'Mô tả', dataIndex: 'description' },
            ]}
            actionBar={{
                isEdit: true,
                isCreate: true,
                isDelete: true
            }}
            getListAction={businessActions.getList}
            deleteAction={businessActions.delete}
        >
        </ListPageContainer>

    )
}

export default ListPage;