import { Form, Row, Col } from 'antd';


import { TextField, UploadImageField } from '@/components/Form';

const BusinessAreaForm = ({
    form,
    formId,
    detailData,
    isCreating,
    onSubmit
}) => {
    const handleSubmit = (values) => {
        // console.log(values);
        onSubmit(values);
    }
    return (
        <Form
            id={formId}
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            initialValues={{ ...detailData, period: 0 }}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <TextField
                        fieldName="name"
                        label="Lĩnh vực"
                        required
                        requiredMsg="Nhập Lĩnh vực"
                        placeholder="Nhập Lĩnh vực"
                    />
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="description"
                        label="Mô tả"
                        required
                        requiredMsg="Nhập mô tả"
                        placeholder="Nhập mô tả"
                    />
                </Col>

                <Col span={12}>
                    <Form.Item
                        name="image"
                        label="Ảnh đại diện"
                        required
                    >
                        <UploadImageField
                            accept={'.png,.jpeg,.jgp'}
                            fieldName="image"
                            name="image"
                            required
                            requiredMsg="Vui lòng chọn ảnh đại diện"
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default BusinessAreaForm;