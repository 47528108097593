import { Form, Row, Col } from 'antd';


import { TextField, UploadImageField } from '@/components/Form';


const InformationForm = ({
    form,
    formId,
    detailData,
    isCreating,
    onSubmit
}) => {
    const handleSubmit = (values) => {
        // console.log(values);
        onSubmit(values);
    }
    return (
        <Form
            id={formId}
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            initialValues={{ ...detailData, period: 0 }}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <TextField
                        fieldName="fullName"
                        label="Tên đầy đủ"
                        required
                        requiredMsg="Nhập tên công ty đầy đủ"
                        placeholder="Nhập tên công ty đầy đủ"
                    />
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="shortName"
                        label="Tên viết tắt"
                        required
                        requiredMsg="Nhập tên viết tắt"
                        placeholder="Nhập tên viết tắt"
                    />
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="taxCode"
                        label="Mã số thuế"
                        required
                        requiredMsg="Nhập Mã số thuế"
                        placeholder="Nhập Mã số thuế"
                    />
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="address"
                        label="Địa chỉ"
                        required
                        requiredMsg="Nhập Địa chỉ"
                        placeholder="Nhập Địa chỉ"
                    />
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="phone"
                        label="Số điện thoại"
                        required
                        requiredMsg="Nhập Số điện thoại"
                        placeholder="Nhập Số điện thoại"
                    />
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="email"
                        label="Email"
                        required
                        requiredMsg="Nhập Email"
                        placeholder="Nhập Email"
                    />
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="website"
                        label="Website"
                        required
                        requiredMsg="Nhập Website"
                        placeholder="Nhập Website"
                    />
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="image"
                        label="Hình ảnh công ty"
                        required
                    >
                        <UploadImageField
                            accept={'.png,.jpeg,.jgp'}
                            fieldName="image"
                            name="image"
                            required
                            requiredMsg="Please choose a banner image"
                        />
                    </Form.Item>
                </Col>

            </Row>
        </Form>
    );
};

export default InformationForm;