import { Form, Row, Col } from 'antd';


import { NumericField, TextField, UploadImageField } from '@/components/Form';
import { AsyncDropdownField } from '@/components';

import { statusDDL } from '@/constants/masterData';

const EmployeeForm = ({
    form,
    formId,
    detailData,
    isCreating,
    onSubmit
}) => {
    const handleSubmit = (values) => {
        // console.log(values);
        onSubmit(values);
    }
    return (
        <Form
            id={formId}
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            initialValues={{ ...detailData, period: 0 }}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <TextField
                        fieldName="name"
                        label="Họ tên"
                        required
                        requiredMsg="Nhập họ tên"
                        placeholder="Nhập  họ tên"
                    />
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="role"
                        label="Chức vụ"
                        required
                        requiredMsg="Nhập chức vụ"
                        placeholder="Nhập chức vụ"
                    />
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="linkedin"
                        label="Linkedin"
                        required
                        requiredMsg="Nhập link linkedin"
                        placeholder="Nhập link linkedin"
                    />
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="facebook"
                        label="Facebook"
                        required
                        requiredMsg="Nhập link facebook"
                        placeholder="Nhập link facebook"
                    />
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="instagram"
                        label="Instagram"
                        required
                        requiredMsg="Nhập link instagram"
                        placeholder="Nhập link instagram"
                    />
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="twitter"
                        label="Twitter"
                        required
                        requiredMsg="Nhập link twitter"
                        placeholder="Nhập link twitter"
                    />
                </Col>
                <Col span={12}>
                    <NumericField
                        fieldName="position"
                        label="Vị trí sắp xếp"
                        required
                        requiredMsg="Nhập vị trí sắp xếp"
                        placeholder="Nhập vị trí sắp xếp"
                    />
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="image"
                        label="Ảnh đại diện"
                        required
                    >
                        <UploadImageField
                            accept={'.png,.jpeg,.jgp'}
                            fieldName="image"
                            name="image"
                            required
                            requiredMsg="Chọn ảnh đại diện"
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default EmployeeForm;