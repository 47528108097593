import { ListPageContainer } from '@/components';
import { informationActions } from '@/redux/actions';
import paths from '@/constants/paths';

const ListPage = () => {

    return (
        <ListPageContainer
            disablePagination={true}
            objectName="Thông tin công ty"
            detailUrl={paths.informationDetail}
            // createUrl={paths.bannerCreate}
            breadcrumbs={[
                { breadcrumbName: 'Giới thiệu' },
                { breadcrumbName: 'Thông tin công ty' }
            ]}
            columns={[
                { title: 'Id', dataIndex: 'id' },
                { title: 'Image', dataIndex: 'image', render: (imageUrl) => <><img src={imageUrl} alt='imgCompany' style={{ width: '500px', height: '250px', objectFit: 'cover' }} /></> },
                { title: 'Tên đầy đủ', dataIndex: 'fullName' },
                { title: 'Tên viết tắt', dataIndex: 'shortName' },
                { title: 'Mã số thuế', dataIndex: 'taxCode' },
                { title: 'Địa chỉ', dataIndex: 'address' },
                { title: 'Số điện thoại', dataIndex: 'phone' },
                { title: 'Email', dataIndex: 'email' },
                { title: 'Website', dataIndex: 'website' }
            ]}
            actionBar={{
                isEdit: true,
                // isCreate: true,
                // isDelete: true
            }}
            getListAction={informationActions.getList}
        // deleteAction={bannerActions.delete}
        >
        </ListPageContainer>

    )
}

export default ListPage;