import { Form, Row, Col } from 'antd';


import { TextField } from '@/components/Form';
import { AsyncDropdownField } from '@/components';

import { newsStatusDDL } from '@/constants/masterData';
import DatePickerField from '@/components/Form/DatePickerField';
import { Link } from 'react-router-dom';

const CommentForm = ({
    form,
    formId,
    detailData,
    isCreating,
    onSubmit
}) => {
    const handleSubmit = (values) => {
        // console.log(values);
        onSubmit(values);
    }
    return (
        <Form
            id={formId}
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            initialValues={{ ...detailData, period: 0 }}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <TextField
                        fieldName={"newsTitle"}
                        label="Tiêu đề bài viết"
                        required
                        readOnly={true}
                    />
                    <Link to={`/news/${detailData.newsId}`}>Xem bài viết</Link>
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="name"
                        label="Người bình luận"
                        required
                        readOnly={true}
                    />
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="email"
                        label="Email"
                        required
                        readOnly={true}
                    />
                </Col>

                <Col span={12}>
                    <TextField
                        fieldName="content"
                        label="Nội dung"
                        required
                        readOnly={true}
                    />
                </Col>
                <Col span={12}>
                    <DatePickerField
                        fieldName="dateCreated"
                        label="Ngày tạo"
                        required
                        readOnly={true}
                    />
                </Col>
                <Col span={12}>
                    <AsyncDropdownField
                        fieldName="status"
                        label="Status"
                        required
                        requiredMsg="Please choose an option"
                        placeholder="Choose status of this banner"
                        options={newsStatusDDL}
                    />
                </Col>
            </Row>
        </Form>
    );
};

export default CommentForm;