import useFetch from '@/hooks/useFetch';

import { DropdownField } from './Form';

const AsyncDropdownField = ({ fieldName, apiConfig, mappingData, ...rest}) => {
    const { data, loading } = useFetch(apiConfig, {
        immediate: true,
        mappingData,
    });

    return (
        <DropdownField
            fieldName={fieldName}
            loading={loading}
            options={data || []}
            {...rest}
        />
    );
};

export default AsyncDropdownField;