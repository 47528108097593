import { Form, Row, Col } from 'antd';


import { TextField, UploadImageField } from '@/components/Form';


const PartnerForm = ({
    form,
    formId,
    detailData,
    isCreating,
    onSubmit
}) => {
    const handleSubmit = (values) => {
        // console.log(values);
        onSubmit(values);
    }
    return (
        <Form
            id={formId}
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            initialValues={{ ...detailData, period: 0 }}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="logo"
                        label="Logo"
                        required
                    >
                        <UploadImageField
                            accept={'.png,.jpeg,.jgp'}
                            fieldName="logo"
                            name="logo"
                            required
                            requiredMsg="Vui lòng chọn ảnh"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="name"
                        label="Tên khách hàng"
                        required
                        requiredMsg="Nhập tên khách hàng"
                        placeholder="Nhập tên khách hàng"
                    />
                </Col>
               
                <Col span={12}>
                    <TextField
                        fieldName="email"
                        label="Email liên hệ"
                        required
                        requiredMsg="Nhập email liên hệ"
                        placeholder="Nhập email liên hệ"
                    />
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="phone"
                        label="Số điện thoại"
                        required
                        requiredMsg="Nhập số điện thoại"
                        placeholder="Nhập số điện thoại"
                    />
                </Col>
            </Row>
        </Form>
    );
};

export default PartnerForm;