import { useParams } from 'react-router-dom';

import { SavePageContainer } from '@/components';
import VisionForm from './VisionForm';

import paths from '@/constants/paths';
import { visionActions } from '@/redux/actions';

const SavePage = () => {
    const { id } = useParams();
    const isCreating = !id;

    const getBreadcrumbs = (data) => {
        return [
            { breadcrumbName: 'Giới thiệu' },
            { breadcrumbName: 'Danh sách banner', path: paths.visionList },
            { breadcrumbName: isCreating ? 'Thêm mới thông điệp và tầm nhìn' : 'Cập nhật thông điệp và tầm nhìn' }
        ];
    }
    return (
        <SavePageContainer
            form={VisionForm}
            isCreating={!id}
            objectName="thông điệp và tầm nhìn"
            pageTitle={isCreating ? "Thêm mới thông điệp và tầm nhìn" : "Cập nhật thông tin thông điệp và tầm nhìn"}
            listUrl={paths.visionList}
            detailUrl={paths.visionDetail}
            // createAction={visionActions.create}
            updateAction={visionActions.update}
            getDetailAction={visionActions.getDetail}
            getBreadcrumbs={getBreadcrumbs}
        />

    )
}

export default SavePage;