export const apicmsUrl = process.env.REACT_APP_ECOM_API;
export const DEFAULT_TABLE_ITEM_SIZE = 10;

// Datetime format
export const DATE_FORMAT_DISPLAY = 'DD/MM/YYYY';
export const DATE_FORMAT_VALUE = 'YYYY-MM-DD';
export const DATETIME_FORMAT_VALUE = 'YYYY-MM-DD hh:mm:ss';
export const DATETIME_FORMAT_DISPLAY = 'DD/MM/YYYY HH:mm:ss';

export const storageKeys = {
    ACCESS_TOKEN: 'bincon-access-token',
    REFRESH_TOKEN: 'bincon-refresh-token',
}

export const fieldTypes = {
    TEXT: 'TEXT',
    NUMBER: 'NUMBER',
    SELECT: 'SELECT',
    AUTOCOMPLETE: 'AUTOCOMPLETE',
    DATE: 'DATE',
    UPLOAD: 'UPLOAD',
    RADIO: 'RADIO',
    BOOLEAN: 'BOOLEAN',
    CHECKBOX: 'CHECKBOX'
}

export const commonStatus = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    LOCK: 'LOCK'
}

export const userRoles = {
    ADMIN: 'ADMIN',
    TEACHER: 'TEACHER',
    OPERATOR: 'OPERATOR',
    SALE: 'SALE',
}

export const productAttributeInputTypes = {
    DROPDOWN: 'DROPDOWN',
    MULTISELECT: 'MULTISELECT',
    PLAIN_TEXT: 'PLAIN_TEXT',
    RICH_TEXT: 'RICH_TEXT'
}

export const attributeTypes = {
    ATTRIBUTE: 'attribute',
    VARIANT: 'variant'
}

export const currencyTypes = {
    VND: 'VND',
}

export const PACKAGES = {
    BASIC: "BASIC",
    ADVANCE: "ADVANCE",
    INTERNATIONAL: "INTERNATIONAL",
}

export const teacherSourceTypes = {
    DTP: 'DTP',
    UNKNOWN: 'UNKNOWN',
}

export const statusTypes = {
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
    PROCESSING: 'PROCESSING',
}

export const orderStatusCode = {
    NEW: 0,
    DONE: 1,
}

export const PAGE_SIZE_OPTIONS = [10, 20, 50];

export const phoneRegExp = /^(84([3|5|7|8|9]([0-9]{8}))|0([3|5|7|8|9]([0-9]{8}))|02[0-9]{1}([0-9]{8}))$/;
export const passwordRegex = /^[-a-zA-Z0-9-~!@#$%^&*()+/?\\_`=;:"'><.,|{}[\]]+(\s+[-a-zA-Z0-9-~!@#$%^&*()+/?\\_`=;:"'><.,|{}[\]]+)*$/;
export const whiteSpaceRegex = /^[^\s]+(\s+[^\s]+)*$/g;
