import { useParams } from 'react-router-dom';

import { SavePageContainer } from '@/components';
import ProjectForm from './projectForm';

import paths from '@/constants/paths';
import { projectActions } from '@/redux/actions';
import dayjs from 'dayjs';

const SavePage = () => {
    const { id } = useParams();
    const isCreating = !id;

    const getBreadcrumbs = (data) => {
        return [
            { breadcrumbName: 'Dự án' },
            { breadcrumbName: 'Danh sách dự án', path: paths.projectList },
            { breadcrumbName: isCreating ? 'Thêm mới dự án' : 'Cập nhật dự án' }
        ];
    }

    const getDetailDataMapping = (response) => {
        return {
            ...response,
            launchDate: dayjs(response.launchDate) 
        }
    }

    return (
        <SavePageContainer
            form={ProjectForm}
            isCreating={!id}
            objectName="dự án"
            pageTitle={isCreating ? "Thêm mới dự án" : "Cập nhật thông tin dự án"}
            listUrl={paths.projectList}
            detailUrl={paths.projectDetail}
            createAction={projectActions.create}
            updateAction={projectActions.update}
            getDetailAction={projectActions.getDetail}
            getBreadcrumbs={getBreadcrumbs}
            getDetailDataMapping={getDetailDataMapping}
        />

    )
}

export default SavePage;