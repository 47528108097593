import { ListPageContainer } from '@/components';
import { employeeActions } from '@/redux/actions';
import paths from '@/constants/paths';

const ListPage = () => {

    return (
        <ListPageContainer
            disablePagination={true}
            objectName="nhân sự"
            detailUrl={paths.employeeDetail}
            createUrl={paths.employeeCreate}
            breadcrumbs={[
                { breadcrumbName: 'Giới thiệu' },
                { breadcrumbName: 'Danh sách nhân sự' }
            ]}
            columns={[
                { title: 'Id', dataIndex: 'id' },
                { title: 'Ảnh đại diện', dataIndex: 'image', render: (imageUrl) => <><img src={imageUrl} alt='imgBanner' style={{ width: '250px', height: '500px', objectFit: 'contain' }} /></> },
                { title: 'Tên nhân sự', dataIndex: 'name' },
                { title: 'Chức vụ', dataIndex: 'role' },
                { title: 'Vị trí sắp xếp', dataIndex: 'position' },
            ]}
            actionBar={{
                isEdit: true,
                isCreate: true,
                isDelete: true
            }}
            getListAction={employeeActions.getList}
            deleteAction={employeeActions.delete}
        >
        </ListPageContainer>

    )
}

export default ListPage;