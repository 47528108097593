import { ListPageContainer } from '@/components';
import { projectActions } from '@/redux/actions';
import paths from '@/constants/paths';
import { formatDate } from '@/utils/date';

const ListPage = () => {

    return (
        <ListPageContainer
            disablePagination={true}
            objectName="dự án"
            detailUrl={paths.projectDetail}
            createUrl={paths.projectCreate}
            breadcrumbs={[
                { breadcrumbName: 'Dự án' },
                { breadcrumbName: 'Danh sách dự án' }
            ]}
            columns={[
                {
                    title: 'Ảnh đại diện', dataIndex: 'avatarImage', align: 'center',
                    render: (imageUrl) => <><img src={imageUrl} alt='imgBanner' style={{ width: '100px', height: '100px', objectFit: 'cover' }} /></>,
                },
                { title: 'Mã dự án', dataIndex: 'name', align: 'center' },
                { title: 'Tên dự án', dataIndex: 'name', align: 'center' },
                {
                    title: 'Ngày hoạt động', dataIndex: 'launchDate', align: 'center',
                    render: (createdDate) => <>{formatDate(createdDate)}</>,
                },
            ]}
            actionBar={{
                isEdit: true,
                isCreate: true,
                isDelete: true
            }}
            getListAction={projectActions.getList}
            deleteAction={projectActions.delete}
        >
        </ListPageContainer>

    )
}

export default ListPage;