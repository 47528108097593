const paths = {
    login: '/login',
    profile: '/profile',
    bannerList: '/banner',
    bannerCreate: '/banner/create',
    bannerDetail: '/banner/:id',
    // ----------------------------
    informationList: '/information',
    informationCreate: '/information/create',
    informationDetail: '/information/:id',
    //-----------------------------
    visionList: '/vision',
    visionCreate: '/vision/create',
    visionDetail: '/vision/:id',
    //-----------------------------
    coreValueList: '/coreValue',
    coreValueCreate: '/coreValue/create',
    coreValueDetail: '/coreValue/:id',
    //-----------------------------
    employeeList: '/employee',
    employeeCreate: '/employee/create',
    employeeDetail: '/employee/:id',
    //-----------------------------
    businessAreaList: '/businessArea',
    businessAreaCreate: '/businessArea/create',
    businessAreaDetail: '/businessArea/:id',
    //-----------------------------
    partnerList: '/partner',
    partnerCreate: '/partner/create',
    partnerDetail: '/partner/:id',
    //-----------------------------
    projectList: '/project',
    projectCreate: '/project/create',
    projectDetail: '/project/:id',
    //-----------------------------
    newsList: '/news',
    newsCreate: '/news/create',
    newsDetail: '/news/:id',
    //-----------------------------
    documentList: '/document',
    documentCreate: '/document/create',
    documentDetail: '/document/:id',
    //-----------------------------
    candidateList: '/candidate',
    candidateCreate: '/candidate/create',
    candidateDetail: '/candidate/:id',
    //-----------------------------
    commentList: '/comment',
    commentCreate: '/comment/create',
    commentDetail: '/comment/:id',
}

export default paths;