import { ListPageContainer } from '@/components';
import { commentActions } from '@/redux/actions';
import paths from '@/constants/paths';
import { formatDate } from '@/utils/date';

const ListPage = () => {

    return (
        <ListPageContainer
            disablePagination={true}
            objectName="bình luận"
            detailUrl={paths.commentDetail}
            // createUrl={paths.commentCreate}
            breadcrumbs={[
                { breadcrumbName: 'Tin tức' },
                { breadcrumbName: 'Danh sách bình luận' }
            ]}
            columns={[
                { title: 'Id', dataIndex: 'id' },
                { title: 'Người bình luận', dataIndex: 'name' },
                { title: 'Nội dung bình luận', dataIndex: 'content' },
                { title: 'Ngày bình luận', dataIndex: 'dateCreated', render: (dateCreated) => (<>{formatDate(dateCreated)}</>) },
                { title: 'Trạng thái', dataIndex: 'status', render: (status) => (<>{status === 'NEW' ? 'Mới' : status === 'APPROVED' ? 'Duyệt' : status === 'REJECTED' ? "Từ chối" : 'null'}</>) },
            ]}
            actionBar={{
                isEdit: true,
                // isCreate: true,
                // isDelete: true
            }}
            getListAction={commentActions.getList}
            deleteAction={commentActions.delete}
        >
        </ListPageContainer>

    )
}

export default ListPage;