import { apicmsUrl } from '@/constants';

const baseHeader = {
    'Content-Type': 'application/json'
}

const multipartFormHeader = {
    'Content-Type': 'multipart/form-data'
}

const apiConfig = {
    account: {
        login: {
            path: `${apicmsUrl}/cms/auth/login`,
            method: 'POST',
            headers: baseHeader,
            isPublic: true,
        },
        refreshToken: {
            path: `${apicmsUrl}/cms/auth/refresh-token`,
            method: 'POST',
            headers: baseHeader,
            isPublic: true,
        },
    },
    media: {
        image: {
            path: `${apicmsUrl}/api/files/upload`,
            method: 'POST',
            headers: multipartFormHeader
        }
    },
    banner: {
        getList: {
            path: `${apicmsUrl}/cms/banner/all`,
            method: 'GET',
            headers: baseHeader,
        },
        getDetail: {
            path: `${apicmsUrl}/cms/banner/detail/:id`,
            method: 'GET',
            headers: baseHeader,
        },
        create: {
            path: `${apicmsUrl}/cms/banner/create`,
            method: 'POST',
            headers: baseHeader,
        },
        update: {
            path: `${apicmsUrl}/cms/banner/update`,
            method: 'PUT',
            headers: baseHeader,
        },
        deleted: {
            path: `${apicmsUrl}/cms/banner/delete/:id`,
            method: 'DELETE',
            headers: baseHeader,
        }
    },
    infoCompany: {
        getList: {
            path: `${apicmsUrl}/cms/company/all`,
            method: 'GET',
            headers: baseHeader,
        },
        getDetail: {
            path: `${apicmsUrl}/cms/company/detail/:id`,
            method: 'GET',
            headers: baseHeader,
        },
        create: {
            path: `${apicmsUrl}/cms/company/create`,
            method: 'POST',
            headers: baseHeader,
        },
        update: {
            path: `${apicmsUrl}/cms/company/update`,
            method: 'PUT',
            headers: baseHeader,
        },
        deleted: {
            path: `${apicmsUrl}/cms/company/delete/:id`,
            method: 'DELETE',
            headers: baseHeader,
        }
    },
    vision: {
        getList: {
            path: `${apicmsUrl}/cms/vision/all`,
            method: 'GET',
            headers: baseHeader,
        },
        getDetail: {
            path: `${apicmsUrl}/cms/vision/detail/:id`,
            method: 'GET',
            headers: baseHeader,
        },
        create: {
            path: `${apicmsUrl}/cms/vision/create`,
            method: 'POST',
            headers: baseHeader,
        },
        update: {
            path: `${apicmsUrl}/cms/vision/update`,
            method: 'PUT',
            headers: baseHeader,
        },
        deleted: {
            path: `${apicmsUrl}/cms/vision/delete/:id`,
            method: 'DELETE',
            headers: baseHeader,
        }
    },
    coreValue: {
        getList: {
            path: `${apicmsUrl}/cms/core-value/all`,
            method: 'GET',
            headers: baseHeader,
        },
        getDetail: {
            path: `${apicmsUrl}/cms/core-value/detail/:id`,
            method: 'GET',
            headers: baseHeader,
        },
        create: {
            path: `${apicmsUrl}/cms/core-value/create`,
            method: 'POST',
            headers: baseHeader,
        },
        update: {
            path: `${apicmsUrl}/cms/core-value/update`,
            method: 'PUT',
            headers: baseHeader,
        },
        deleted: {
            path: `${apicmsUrl}/cms/core-value/delete/:id`,
            method: 'DELETE',
            headers: baseHeader,
        }
    },
    employee: {
        getList: {
            path: `${apicmsUrl}/cms/employee/all`,
            method: 'GET',
            headers: baseHeader,
        },
        getDetail: {
            path: `${apicmsUrl}/cms/employee/detail/:id`,
            method: 'GET',
            headers: baseHeader,
        },
        create: {
            path: `${apicmsUrl}/cms/employee/create`,
            method: 'POST',
            headers: baseHeader,
        },
        update: {
            path: `${apicmsUrl}/cms/employee/update`,
            method: 'PUT',
            headers: baseHeader,
        },
        deleted: {
            path: `${apicmsUrl}/cms/employee/delete/:id`,
            method: 'DELETE',
            headers: baseHeader,
        }
    },
    businessArea: {
        getList: {
            path: `${apicmsUrl}/cms/business-area/all`,
            method: 'GET',
            headers: baseHeader,
        },
        getDetail: {
            path: `${apicmsUrl}/cms/business-area/detail/:id`,
            method: 'GET',
            headers: baseHeader,
        },
        create: {
            path: `${apicmsUrl}/cms/business-area/create`,
            method: 'POST',
            headers: baseHeader,
        },
        update: {
            path: `${apicmsUrl}/cms/business-area/update`,
            method: 'PUT',
            headers: baseHeader,
        },
        deleted: {
            path: `${apicmsUrl}/cms/business-area/delete/:id`,
            method: 'DELETE',
            headers: baseHeader,
        }
    },
    partner: {
        getList: {
            path: `${apicmsUrl}/cms/partner/all`,
            method: 'GET',
            headers: baseHeader,
        },
        getDetail: {
            path: `${apicmsUrl}/cms/partner/detail/:id`,
            method: 'GET',
            headers: baseHeader,
        },
        create: {
            path: `${apicmsUrl}/cms/partner/create`,
            method: 'POST',
            headers: baseHeader,
        },
        update: {
            path: `${apicmsUrl}/cms/partner/update`,
            method: 'PUT',
            headers: baseHeader,
        },
        deleted: {
            path: `${apicmsUrl}/cms/partner/delete/:id`,
            method: 'DELETE',
            headers: baseHeader,
        }
    },
    project: {
        getList: {
            path: `${apicmsUrl}/cms/project/all`,
            method: 'GET',
            headers: baseHeader,
        },
        getDetail: {
            path: `${apicmsUrl}/cms/project/detail/:id`,
            method: 'GET',
            headers: baseHeader,
        },
        create: {
            path: `${apicmsUrl}/cms/project/create`,
            method: 'POST',
            headers: baseHeader,
        },
        update: {
            path: `${apicmsUrl}/cms/project/update`,
            method: 'PUT',
            headers: baseHeader,
        },
        deleted: {
            path: `${apicmsUrl}/cms/project/delete/:id`,
            method: 'DELETE',
            headers: baseHeader,
        }
    },
    news: {
        getList: {
            path: `${apicmsUrl}/cms/news/all`,
            method: 'GET',
            headers: baseHeader,
        },
        getDetail: {
            path: `${apicmsUrl}/cms/news/detail/:id`,
            method: 'GET',
            headers: baseHeader,
        },
        create: {
            path: `${apicmsUrl}/cms/news/create`,
            method: 'POST',
            headers: baseHeader,
        },
        update: {
            path: `${apicmsUrl}/cms/news/update`,
            method: 'PUT',
            headers: baseHeader,
        },
        deleted: {
            path: `${apicmsUrl}/cms/news/delete/:id`,
            method: 'DELETE',
            headers: baseHeader,
        }
    },
    document: {
        getList: {
            path: `${apicmsUrl}/cms/document/all`,
            method: 'GET',
            headers: baseHeader,
        },
        getDetail: {
            path: `${apicmsUrl}/cms/document/detail/:id`,
            method: 'GET',
            headers: baseHeader,
        },
        create: {
            path: `${apicmsUrl}/cms/document/create`,
            method: 'POST',
            headers: baseHeader,
        },
        update: {
            path: `${apicmsUrl}/cms/document/update`,
            method: 'PUT',
            headers: baseHeader,
        },
        deleted: {
            path: `${apicmsUrl}/cms/document/delete/:id`,
            method: 'DELETE',
            headers: baseHeader,
        }
    },
    candidate: {
        getList: {
            path: `${apicmsUrl}/cms/candidate/all`,
            method: 'GET',
            headers: baseHeader,
        },
        getDetail: {
            path: `${apicmsUrl}/cms/candidate/detail/:id`,
            method: 'GET',
            headers: baseHeader,
        },
        create: {
            path: `${apicmsUrl}/cms/candidate/create`,
            method: 'POST',
            headers: baseHeader,
        },
        update: {
            path: `${apicmsUrl}/cms/candidate/update`,
            method: 'PUT',
            headers: baseHeader,
        },
        deleted: {
            path: `${apicmsUrl}/cms/candidate/delete/:id`,
            method: 'DELETE',
            headers: baseHeader,
        }
    },
    comment: {
        getList: {
            path: `${apicmsUrl}/cms/comment/all`,
            method: 'GET',
            headers: baseHeader,
        },
        getDetail: {
            path: `${apicmsUrl}/cms/comment/detail/:id`,
            method: 'GET',
            headers: baseHeader,
        },
        create: {
            path: `${apicmsUrl}/cms/comment/create`,
            method: 'POST',
            headers: baseHeader,
        },
        update: {
            path: `${apicmsUrl}/cms/comment/update`,
            method: 'PUT',
            headers: baseHeader,
        },
        deleted: {
            path: `${apicmsUrl}/cms/comment/delete/:id`,
            method: 'DELETE',
            headers: baseHeader,
        }
    }

}

export default apiConfig;