// import { commonStatus, productAttributeInputTypes, currencyTypes, userRoles, teacherSourceTypes, statusTypes } from '.';

export const statusDDL = [
    { value: true, label: 'Active' },
    { value: false, label: 'Inactive' }
]
export const featuredDDL = [
    { value: true, label: 'Nổi bật' },
    { value: false, label: 'Không nổi bật' }
]
export const newsStatusDDL = [
    { value: 'NEW', label: 'New' },
    { value: 'APPROVED', label: 'Approved' },
    { value: 'REJECTED', label: 'Stop' }
]

export const categoryNewsDDL = [
    { value: 1, label: 'Công nghệ và kỹ thuật' },
    { value: 2, label: 'Con người & kỹ năng mềm' },
    { value: 3, label: 'Doanh nghiệp & quy trình' }
]




