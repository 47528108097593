import { ListPageContainer } from '@/components';
import { visionActions } from '@/redux/actions';
import paths from '@/constants/paths';
const ListPage = () => {

    return (
        <ListPageContainer
            disablePagination={true}
            objectName="banner"
            detailUrl={paths.visionDetail}
            // createUrl={paths.bannerCreate}
            breadcrumbs={[
                { breadcrumbName: 'Giới thiệu' },
                { breadcrumbName: 'Thông điệp và tầm nhìn' }
            ]}
            columns={[
                { title: 'Id', dataIndex: 'id' },
                { title: 'Image', dataIndex: 'avatarImage', render: (imageUrl) => <><img src={imageUrl} alt='imgAuthor' style={{ width: '100%', height: '100%', objectFit: 'cover' }} /></> },
                { title: 'Người truyền thông điệp', dataIndex: 'author' },
                { title: 'Nội dung', dataIndex: 'content' },
                { title: 'Chức vụ', dataIndex: 'role' },
            ]}
            actionBar={{
                isEdit: true,
                // isCreate: true,
                // isDelete: true
            }}
            getListAction={visionActions.getList}
        >
        </ListPageContainer>

    )
}

export default ListPage;