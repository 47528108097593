import { useParams } from 'react-router-dom';

import { SavePageContainer } from '@/components';
import DocumentForm from './documentForm';

import paths from '@/constants/paths';
import { documentActions } from '@/redux/actions';

const SavePage = () => {
    const { id } = useParams();
    const isCreating = !id;

    const getBreadcrumbs = (data) => {
        return [
            { breadcrumbName: 'Tài liệu' },
            { breadcrumbName: 'Danh sách tài liệu', path: paths.documentList },
            { breadcrumbName: isCreating ? 'Thêm mới tài liệu' : 'Cập nhật tài liệu' }
        ];
    }
    return (
        <SavePageContainer
            form={DocumentForm}
            isCreating={!id}
            objectName="tài liệu"
            pageTitle={isCreating ? "Thêm mới tài liệu" : "Cập nhật thông tin tài liệu"}
            listUrl={paths.documentList}
            detailUrl={paths.documentDetail}
            createAction={documentActions.create}
            updateAction={documentActions.update}
            getDetailAction={documentActions.getDetail}
            getBreadcrumbs={getBreadcrumbs}
        />

    )
}

export default SavePage;