import { useParams } from 'react-router-dom';

import { SavePageContainer } from '@/components';
import CommentForm from './commentForm';

import paths from '@/constants/paths';
import { commentActions } from '@/redux/actions';
import dayjs from 'dayjs';

const SavePage = () => {
    const { id } = useParams();
    const isCreating = !id;

    const getBreadcrumbs = (data) => {
        return [
            { breadcrumbName: 'Giới thiệu' },
            { breadcrumbName: 'Danh sách bình luận', path: paths.commentList },
            { breadcrumbName: isCreating ? 'Thêm mới bình luận' : 'Cập nhật bình luận' }
        ];
    }
    const getDetailDataMapping = (response) => {
        return {
            ...response,
            dateCreated: dayjs(response.dateCreated) 
        }
    }
    return (
        <SavePageContainer
            form={CommentForm}
            isCreating={!id}
            objectName="bình luận"
            pageTitle={isCreating ? "Thêm mới bình luận" : "Cập nhật thông tin bình luận"}
            listUrl={paths.commentList}
            detailUrl={paths.commentDetail}
            createAction={commentActions.create}
            updateAction={commentActions.update}
            getDetailAction={commentActions.getDetail}
            getBreadcrumbs={getBreadcrumbs}
            getDetailDataMapping={getDetailDataMapping}
        />

    )
}

export default SavePage;