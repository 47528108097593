import { Form, Row, Col } from 'antd';


import { TextField, UploadImageField } from '@/components/Form';


const DocumentForm = ({
    form,
    formId,
    detailData,
    isCreating,
    onSubmit
}) => {
    const handleSubmit = (values) => {
        // console.log(values);
        onSubmit(values);
    }
    return (
        <Form
            id={formId}
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            initialValues={{ ...detailData, period: 0 }}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="image"
                        label="Ảnh đại diện"
                        required
                    >
                        <UploadImageField
                            fieldName="image"
                            required
                            requiredMsg="Vui lòng chọn ảnh"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="name"
                        label="Tên tài liệu"
                        required
                        requiredMsg="Nhập Tên tài liệu"
                        placeholder="Nhập Tên tài liệu"
                    />
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="description"
                        label="Mô tả "
                        required
                        requiredMsg="Nhập Mô tả "
                        placeholder="Nhập Mô tả "
                    />
                </Col>
                <Col>
                    <Form.Item
                        name="fileName"
                        label="Chọn file"
                        required
                    >
                        <UploadImageField
                            accept={'.pdf'}
                            fieldName="fileName"
                            required
                            requiredMsg="Vui lòng chọn file"
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default DocumentForm;