
export const setLocalStorage = (key, data) => {
    if (typeof window !== 'undefined' && window.localStorage) {
        window.localStorage.setItem(key, JSON.stringify(data));
    }
}

export const getLocalStorage= (key) => {
    if (typeof window !== 'undefined' && window.localStorage) {
        const jsonData = window.localStorage.getItem(key);
        if (jsonData) {
            try {
                return JSON.parse(jsonData);
            }
            catch {
                return false;
            }
        }
        return false;
    }
    return false;
}

export const removeItem = (key) => {
    if (typeof window !== 'undefined' && window.localStorage) {
        window.localStorage.removeItem(key);
    }
}