import { useParams } from 'react-router-dom';

import { SavePageContainer } from '@/components';
import CandidateForm from './candidateForm';

import paths from '@/constants/paths';
import { candidateActions } from '@/redux/actions';

const SavePage = () => {
    const { id } = useParams();
    const isCreating = !id;

    const getBreadcrumbs = (data) => {
        return [
            { breadcrumbName: 'Ứng viên' },
            { breadcrumbName: 'Danh sách ứng viên', path: paths.candidateList },
            { breadcrumbName: isCreating ? 'Thêm mới ứng viên' : 'Cập nhật ứng viên' }
        ];
    }
    return (
        <SavePageContainer
            form={CandidateForm}
            isCreating={!id}
            objectName="ứng viên"
            pageTitle={isCreating ? "Thêm mới ứng viên" : "Cập nhật thông tin ứng viên"}
            listUrl={paths.candidateList}
            detailUrl={paths.candidateDetail}
            // createAction={candidateActions.create}
            // updateAction={candidateActions.update}
            getDetailAction={candidateActions.getDetail}
            getBreadcrumbs={getBreadcrumbs}
            isOnlySeeDetail={true}
        />

    )
}

export default SavePage;