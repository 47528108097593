import { ListPageContainer } from '@/components';
import { coreValueActions } from '@/redux/actions';
import paths from '@/constants/paths';

const ListPage = () => {

    return (
        <ListPageContainer
            disablePagination={true}
            objectName="giá trị cốt lõi"
            detailUrl={paths.coreValueDetail}
            createUrl={paths.coreValueCreate}
            breadcrumbs={[
                { breadcrumbName: 'Giới thiệu' },
                { breadcrumbName: 'Danh sách giá trị cốt lõi' }
            ]}
            columns={[
                { title: 'Id', dataIndex: 'id' },
                { title: 'Image', dataIndex: 'image', render: (imageUrl) => <><img src={imageUrl} alt='imgCoreValue' style={{ width: '50px', height: '50px', objectFit: 'contain' }} /></> },
                { title: 'Tên giá trị', dataIndex: 'name' },
                { title: 'Mô tả', dataIndex: 'description' },
            ]}
            actionBar={{
                isEdit: true,
                isCreate: true,
                isDelete: true
            }}
            getListAction={coreValueActions.getList}
            deleteAction={coreValueActions.delete}
        >
        </ListPageContainer>

    )
}

export default ListPage;