import { ListPageContainer } from '@/components';
import { partnerActions } from '@/redux/actions';
import paths from '@/constants/paths';

const ListPage = () => {

    return (
        <ListPageContainer
            disablePagination={true}
            objectName="khách hàng"
            detailUrl={paths.partnerDetail}
            createUrl={paths.partnerCreate}
            breadcrumbs={[
                { breadcrumbName: 'Giới thiệu' },
                { breadcrumbName: 'Danh sách khách hàng' }
            ]}
            columns={[
                { title: 'Id', dataIndex: 'id' },
                { title: 'Image', dataIndex: 'logo', render: (imageUrl) => <><img src={imageUrl} alt='imgBanner' style={{ width: '100px', height: '100px', objectFit: 'cover' }} /></> },
                { title: 'Tên công ty', dataIndex: 'name' },
                { title: 'Email liên hệ', dataIndex: 'email' },
            ]}
            actionBar={{
                isEdit: true,
                isCreate: true,
                isDelete: true
            }}
            getListAction={partnerActions.getList}
            deleteAction={partnerActions.delete}
        >
        </ListPageContainer>

    )
}

export default ListPage;