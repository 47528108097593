import { Form, Row, Col, } from 'antd';


import { TextEditorField, TextField, UploadImageField } from '@/components/Form';
import DatePickerField from '@/components/Form/DatePickerField';
import { AsyncDropdownField } from '@/components';
import { featuredDDL } from '@/constants/masterData';
const ProjectForm = ({
    form,
    formId,
    detailData,
    isCreating,
    onSubmit
}) => {
    const handleSubmit = (values) => {
        onSubmit(values);
    }
    return (
        <Form
            id={formId}
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            initialValues={{ ...detailData, period: 0 }}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <TextField
                        fieldName="projectCode"
                        label="Mã dự án"
                        required
                        requiredMsg="Nhập Mã dự án"
                        placeholder="Nhập Mã dự án"
                    />
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="name"
                        label="Tên dự án"
                        required
                        requiredMsg="Nhập Tên dự án"
                        placeholder="Nhập Tên dự án"
                    />
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="clientName"
                        label="Chủ đầu tư"
                        required
                        requiredMsg="Nhập Chủ đầu tư"
                        placeholder="Nhập Chủ đầu tư"
                    />
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="location"
                        label="Địa chỉ"
                        required
                        requiredMsg="Nhập Địa chỉ"
                        placeholder="Nhập Địa chỉ"
                    />
                </Col>
                <Col span={12}>
                    <DatePickerField
                        fieldName="launchDate"
                        label="Ngày hoạt động"
                        required
                    />
                </Col>
                <Col span={12}>
                    <AsyncDropdownField
                        fieldName="featured"
                        label="Nổi bật"
                        required
                        requiredMsg="Vui lòng chọn trạng thái"
                        placeholder="Vui lòng chọn trạng thái"
                        options={featuredDDL}
                    />
                </Col>
                <Col span={24}>
                    <TextEditorField
                        fieldName="content"
                        label="Mô tả"
                        required
                        requiredMsg="Nhập mô tả"
                    />
                </Col>

                <Col span={12}>
                    <Form.Item
                        name="avatarImage"
                        label="Ảnh đại diện"
                        required
                    >
                        <UploadImageField
                            accept={'.png,.jpeg,.jgp'}
                            fieldName="avatarImage"
                            required
                            requiredMsg="Vui lòng chọn ảnh"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="detailImage"
                        label="Ảnh chi tiết dự án"
                        required
                    >
                        <UploadImageField
                            accept={'.png,.jpeg,.jgp'}
                            fieldName="detailImage"
                            required
                            requiredMsg="Vui lòng chọn ảnh"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={["projectImages"]}
                        label="Hình ảnh liên quan"
                        required
                    >
                        <UploadImageField
                            // accept={'.png,.jpeg,.jgp'}
                            fieldName={["projectImages"]}
                            required
                            requiredMsg="Vui lòng chọn ảnh"
                            maxCount={20}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default ProjectForm;