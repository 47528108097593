import { passwordRegex, phoneRegExp, whiteSpaceRegex } from "@/constants"

export const validationPhone = () => {
    return [
        {
            pattern: phoneRegExp,
            message: "Số điện thoại không hợp lệ!"
        }, 
        {
            len: 10,
            message: 'Số điện thoại cần có 10 chữ số!',
        },
    ]
}

export const validationPassword = (prefixMsg) => {
    return [
        { required: true, message: `${prefixMsg} không được bỏ trống!`},
        {
            pattern: whiteSpaceRegex,
            message: `${prefixMsg} không được bắt đầu và kết thúc bằng khoảng trắng!`
        },
        {
            pattern: passwordRegex,
            message: `${prefixMsg} chỉ được sử dụng chữ cái, số và ký tự đặc biệt thường gặp!`
        },
        {
            min: 8,
            message: `${prefixMsg} phải có tối thiểu 8 ký tự!`
        }
    ]
}

export const validationEmail = () => {
    return [{
        type: "email",
        message: "Email chưa đúng định dạng!"
    }]
}