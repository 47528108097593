import { Form, Row, Col } from 'antd';
import { TextField } from '@/components/Form';

const CandidateForm = ({
    form,
    formId,
    detailData,
    isCreating,
    onSubmit
}) => {
    const handleSubmit = (values) => {
        // console.log(values);
        // onSubmit(values);
    }
    return (
        <Form
            id={formId}
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            initialValues={{ ...detailData, period: 0 }}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="cvLink"
                        label="Link CV ứng viên"
                        required
                    >
                        <a href={`${detailData.cvLink}`}>{detailData.cvLink}</a>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="fullName"
                        label="Họ và tên ứng viên"
                        required
                        requiredMsg="Nhập Tên tài liệu"
                        placeholder="Nhập Tên tài liệu"
                        readOnly={true}
                    />
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="jobTitle"
                        label="Vị trí ứng tuyển "
                        required
                        requiredMsg="Nhập Mô tả "
                        placeholder="Nhập Mô tả "
                        readOnly={true}
                    />
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="email"
                        label="Email"
                        required
                        requiredMsg="Nhập Mô tả "
                        placeholder="Nhập Mô tả "
                        readOnly={true}
                    />
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="content"
                        label="Nội dung ứng tuyển"
                        required
                        requiredMsg="Nhập Mô tả "
                        placeholder="Nhập Mô tả "
                        readOnly={true}
                    />
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="phoneNumber"
                        label="Số điện thoại"
                        required
                        requiredMsg="Nhập Mô tả "
                        placeholder="Nhập Mô tả "
                        readOnly={true}
                    />
                </Col>
            </Row>
        </Form >
    );
};

export default CandidateForm;