import { useParams } from 'react-router-dom';

import { SavePageContainer } from '@/components';
import InformationForm from './InfoForm';

import paths from '@/constants/paths';
import { informationActions } from '@/redux/actions';

const SavePage = () => {
    const { id } = useParams();
    const isCreating = !id;

    const getBreadcrumbs = (data) => {
        return [
            { breadcrumbName: 'Giới thiệu' },
            { breadcrumbName: 'Thông tin công ty', path: paths.informationList },
            { breadcrumbName: isCreating ? 'Thêm mới Thông tin công ty' : 'Cập nhật Thông tin công ty' }
        ];
    }
    return (
        <SavePageContainer
            form={InformationForm}
            isCreating={!id}
            objectName="Thông tin công ty"
            pageTitle={isCreating ? "Thêm mới Thông tin công ty" : "Cập nhật thông tin Thông tin công ty"}
            listUrl={paths.informationList}
            detailUrl={paths.informationDetail}
            updateAction={informationActions.update}
            getDetailAction={informationActions.getDetail}
            getBreadcrumbs={getBreadcrumbs}
        />

    )
}

export default SavePage;