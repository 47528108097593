import paths from '@/constants/paths';
import LoginPage from '@/containers/login/LoginPage';
import ProfilePage from '@/containers/profile';
import BannerListPage from '@/containers/banner/ListPage';
import BannerSavePage from '@/containers/banner/SavePage';
// ------------------------------------------
import InformationListPage from '@/containers/informationCompany/ListPage';
import InformationSavePage from '@/containers/informationCompany/SavePage';
// ---------------------------------------------
import VisionListPage from '@/containers/vision/ListPage';
import VisionSavePage from '@/containers/vision/SavePage';
// ---------------------------------------------
import CoreValueListPage from '@/containers/coreValue/ListPage';
import CoreValueSavePage from '@/containers/coreValue/SavePage';
// ---------------------------------------------
import EmployeeListPage from '@/containers/employee/ListPage';
import EmployeeSavePage from '@/containers/employee/SavePage';
// ---------------------------------------------
import BusinessAreaListPage from '@/containers/businessArea/ListPage';
import BusinessAreaSavePage from '@/containers/businessArea/SavePage';
// ---------------------------------------------
import PartnerListPage from '@/containers/partner/ListPage';
import PartnerSavePage from '@/containers/partner/SavePage';
// ---------------------------------------------
import ProjectListPage from '@/containers/project/ListPage';
import ProjectSavePage from '@/containers/project/SavePage';
// ---------------------------------------------
import NewsListPage from '@/containers/news/ListPage';
import NewsSavePage from '@/containers/news/SavePage';
// ---------------------------------------------
import DocumentListPage from '@/containers/documentPage/ListPage';
import DocumentSavePage from '@/containers/documentPage/SavePage';
// ---------------------------------------------
import CandidateListPage from '@/containers/candidate/ListPage';
import CandidateSavePage from '@/containers/candidate/SavePage';
// ---------------------------------------------
import CommentListPage from '@/containers/comment/ListPage';
import CommentSavePage from '@/containers/comment/SavePage';
// import { userRoles } from '@/constants';

const routes = [
    {
        path: paths.login,
        isPublic: true,
        component: LoginPage
    },
    {
        path: paths.profile,
        component: ProfilePage
    },
    // -----------------------------
    {
        path: paths.bannerList,
        component: BannerListPage,
        // roles: [userRoles.ADMIN]
    },
    {
        path: paths.bannerCreate,
        component: BannerSavePage,
        // roles: [userRoles.ADMIN]
    },
    {
        path: paths.bannerDetail,
        component: BannerSavePage,
        // roles: [userRoles.ADMIN]
    },
    // ----------------------
    {
        path: paths.informationList,
        component: InformationListPage,
        // roles: [userRoles.ADMIN]
    },
    {
        path: paths.informationCreate,
        component: InformationSavePage,
        // roles: [userRoles.ADMIN]
    },
    {
        path: paths.informationDetail,
        component: InformationSavePage,
        // roles: [userRoles.ADMIN]
    },
    //-----------------------------
    {
        path: paths.visionList,
        component: VisionListPage,
        // roles: [userRoles.ADMIN]
    },
    {
        path: paths.visionCreate,
        component: VisionSavePage,
        // roles: [userRoles.ADMIN]
    },
    {
        path: paths.visionDetail,
        component: VisionSavePage,
        // roles: [userRoles.ADMIN]
    },
    //-----------------------------
    {
        path: paths.coreValueList,
        component: CoreValueListPage,
        // roles: [userRoles.ADMIN]
    },
    {
        path: paths.coreValueCreate,
        component: CoreValueSavePage,
        // roles: [userRoles.ADMIN]
    },
    {
        path: paths.coreValueDetail,
        component: CoreValueSavePage,
        // roles: [userRoles.ADMIN]
    },
    //-----------------------------
    {
        path: paths.employeeList,
        component: EmployeeListPage,
        // roles: [userRoles.ADMIN]
    },
    {
        path: paths.employeeCreate,
        component: EmployeeSavePage,
        // roles: [userRoles.ADMIN]
    },
    {
        path: paths.employeeDetail,
        component: EmployeeSavePage,
        // roles: [userRoles.ADMIN]
    },
    //-----------------------------
    {
        path: paths.businessAreaList,
        component: BusinessAreaListPage,
        // roles: [userRoles.ADMIN]
    },
    {
        path: paths.businessAreaCreate,
        component: BusinessAreaSavePage,
        // roles: [userRoles.ADMIN]
    },
    {
        path: paths.businessAreaDetail,
        component: BusinessAreaSavePage,
        // roles: [userRoles.ADMIN]
    },
    //-----------------------------
    {
        path: paths.partnerList,
        component: PartnerListPage,
        // roles: [userRoles.ADMIN]
    },
    {
        path: paths.partnerCreate,
        component: PartnerSavePage,
        // roles: [userRoles.ADMIN]
    },
    {
        path: paths.partnerDetail,
        component: PartnerSavePage,
        // roles: [userRoles.ADMIN]
    },
    //-----------------------------
    {
        path: paths.projectList,
        component: ProjectListPage,
        // roles: [userRoles.ADMIN]
    },
    {
        path: paths.projectCreate,
        component: ProjectSavePage,
        // roles: [userRoles.ADMIN]
    },
    {
        path: paths.projectDetail,
        component: ProjectSavePage,
        // roles: [userRoles.ADMIN]
    },
    //-----------------------------
    {
        path: paths.newsList,
        component: NewsListPage,
        // roles: [userRoles.ADMIN]
    },
    {
        path: paths.newsCreate,
        component: NewsSavePage,
        // roles: [userRoles.ADMIN]
    },
    {
        path: paths.newsDetail,
        component: NewsSavePage,
        // roles: [userRoles.ADMIN]
    },
    //-----------------------------
    {
        path: paths.documentList,
        component: DocumentListPage,
        // roles: [userRoles.ADMIN]
    },
    {
        path: paths.documentCreate,
        component: DocumentSavePage,
        // roles: [userRoles.ADMIN]
    },
    {
        path: paths.documentDetail,
        component: DocumentSavePage,
        // roles: [userRoles.ADMIN]
    },
    //-----------------------------
    {
        path: paths.candidateList,
        component: CandidateListPage,
        // roles: [userRoles.ADMIN]
    },
    {
        path: paths.candidateCreate,
        component: CandidateSavePage,
        // roles: [userRoles.ADMIN]
    },
    {
        path: paths.candidateDetail,
        component: CandidateSavePage,
        // roles: [userRoles.ADMIN]
    },
     //-----------------------------
     {
        path: paths.commentList,
        component: CommentListPage,
        // roles: [userRoles.ADMIN]
    },
    {
        path: paths.commentCreate,
        component: CommentSavePage,
        // roles: [userRoles.ADMIN]
    },
    {
        path: paths.commentDetail,
        component: CommentSavePage,
        // roles: [userRoles.ADMIN]
    },
]

export default routes;
