import { takeLatest } from 'redux-saga/effects';

import { projectActionTypes } from '@/redux/actions';
import apiConfig from '@/constants/apiConfig';

import { processCallbackAction } from '../helper';

const {
    GET_LIST,
    GET_DETAIL,
    CREATE,
    UPDATE,
    DELETE
} = projectActionTypes;

const getList = (action) => {
    return processCallbackAction(apiConfig.project.getList, action);
}


const getDetail = ({ payload }) => {
    const { params, onCompleted, onError } = payload;
    const apiOptions = {
        ...apiConfig.project.getDetail,
        path: apiConfig.project.getDetail.path.replace(':id', params.id)
    }
    return processCallbackAction(apiOptions, { payload: { params: {}, onCompleted, onError } });
}

const create = (action) => {
    return processCallbackAction(apiConfig.project.create, action);
}

const update = (action) => {
    return processCallbackAction(apiConfig.project.update, action);
}

const deleteProject = ({ payload }) => {
    const { params, onCompleted, onError } = payload;
    const apiOptions = {
        ...apiConfig.project.deleted,
        path: apiConfig.project.deleted.path.replace(':id', params.id)
    }
    return processCallbackAction(apiOptions, { payload: { params: {}, onCompleted, onError } });
}

const projectSagas = [
    takeLatest(GET_LIST, getList),
    takeLatest(GET_DETAIL, getDetail),
    takeLatest(CREATE, create),
    takeLatest(UPDATE, update),
    takeLatest(DELETE, deleteProject),
];

export default projectSagas;