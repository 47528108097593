import { all } from 'redux-saga/effects';

import account from './account';
import banner from './banner';
import information from './information'
import vision from './vision'
import coreValue from './coreValue'
import employee from './employee'
import business from './businessArea'
import partner from './partner'
import project from './project'
import news from './news'
import document from './document'
import candidate from './candidate'
import comment from './comment'
const sagas = [
    ...account,
    ...banner,
    ...information,
    ...vision,
    ...coreValue,
    ...employee,
    ...business,
    ...partner,
    ...project,
    ...news,
    ...document,
    ...candidate,
    ...comment
];

function* rootSaga() {
    yield all(sagas);
}

export default rootSaga;