import { useParams } from 'react-router-dom';

import { SavePageContainer } from '@/components';
import PartnerForm from './partnerForm';

import paths from '@/constants/paths';
import { partnerActions } from '@/redux/actions';

const SavePage = () => {
    const { id } = useParams();
    const isCreating = !id;

    const getBreadcrumbs = (data) => {
        return [
            { breadcrumbName: 'Giới thiệu' },
            { breadcrumbName: 'Danh sách khách hàng', path: paths.partnerList },
            { breadcrumbName: isCreating ? 'Thêm mới khách hàng' : 'Cập nhật khách hàng' }
        ];
    }
    return (
        <SavePageContainer
            form={PartnerForm}
            isCreating={!id}
            objectName="khách hàng"
            pageTitle={isCreating ? "Thêm mới khách hàng" : "Cập nhật thông tin khách hàng"}
            listUrl={paths.partnerList}
            detailUrl={paths.partnerDetail}
            createAction={partnerActions.create}
            updateAction={partnerActions.update}
            getDetailAction={partnerActions.getDetail}
            getBreadcrumbs={getBreadcrumbs}
        />

    )
}

export default SavePage;