import { useParams } from 'react-router-dom';

import { SavePageContainer } from '@/components';
import BusinessAreaForm from './businessAreaForm';

import paths from '@/constants/paths';
import { businessActions } from '@/redux/actions';

const SavePage = () => {
    const { id } = useParams();
    const isCreating = !id;

    const getBreadcrumbs = (data) => {
        return [
            { breadcrumbName: 'Giới thiệu' },
            { breadcrumbName: 'Danh sách lĩnh vực hoạt động', path: paths.businessAreaList },
            { breadcrumbName: isCreating ? 'Thêm mới lĩnh vực hoạt động' : 'Cập nhật lĩnh vực hoạt động' }
        ];
    }
    return (
        <SavePageContainer
            form={BusinessAreaForm}
            isCreating={!id}
            objectName="lĩnh vực hoạt động"
            pageTitle={isCreating ? "Thêm mới lĩnh vực hoạt động" : "Cập nhật thông tin lĩnh vực hoạt động"}
            listUrl={paths.businessAreaList}
            detailUrl={paths.businessAreaDetail}
            createAction={businessActions.create}
            updateAction={businessActions.update}
            getDetailAction={businessActions.getDetail}
            getBreadcrumbs={getBreadcrumbs}
        />

    )
}

export default SavePage;