import { useState } from 'react';
import { Form, Row, Col } from 'antd';
import { TextEditorField, TextField, UploadImageField } from '@/components/Form';
import DatePickerField from '@/components/Form/DatePickerField';
import { AsyncDropdownField } from '@/components';
import { categoryNewsDDL, featuredDDL, newsStatusDDL } from '@/constants/masterData';
import { useEffect } from 'react';


const NewsForm = ({
    form,
    formId,
    detailData,
    isCreating,
    onSubmit
}) => {
    const [newsCategoryId, setNewsCategoryId] = useState();
    const handleSubmit = (values) => {
        delete values.newsCategory
        values.newsCategoryId = newsCategoryId
        onSubmit(values);
    }
    const onChange = (values) => {
        setNewsCategoryId(values)
    }
    useEffect(() => {
      setNewsCategoryId(detailData.newsCategory?.id)
    }, [detailData.newsCategory])
    return (
        <Form
            id={formId}
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            initialValues={{ ...detailData, period: 0 }}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <TextField
                        fieldName="title"
                        label="Tiêu đề"
                        required
                        requiredMsg="Nhập tiêu đề"
                        placeholder="Nhập tiêu đề"
                    />
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="author"
                        label="Người viết bài"
                        required
                        requiredMsg="Nhập Người viết bài"
                        placeholder="Nhập Người viết bài"
                    />
                </Col>
                <Col span={12}>
                    <AsyncDropdownField
                        fieldName={["newsCategory","id"]}
                        label="Loại bài viết"
                        required
                        requiredMsg="Vui lòng chọn loại bài viết"
                        options={categoryNewsDDL}
                        onChange={(values) => onChange(values)}
                    />
                </Col>
                <Col span={12}>
                    <DatePickerField
                        fieldName="dateCreated"
                        label="Ngày viết bài"
                        required
                        requiredMsg="Vui lòng chọn ngày viết bài"
                    />
                </Col>
                <Col span={12}>
                    <AsyncDropdownField
                        fieldName="status"
                        label="Trạng thái"
                        required
                        requiredMsg="Vui lòng chọn trạng thái"
                        options={newsStatusDDL}
                    />
                </Col>

                <Col span={12}>
                    <DatePickerField
                        fieldName="dateReview"
                        label="Ngày duyệt bài"
                    // required
                    // requiredMsg="Vui lòng chọn ngày duyệt bài"
                    />
                </Col>
                <Col span={12}>
                    <AsyncDropdownField
                        fieldName="featured"
                        label="Nổi bật"
                        required
                        requiredMsg="Vui lòng chọn trạng thái"
                        options={featuredDDL}
                    />
                </Col>
                <Col span={24}>
                    <TextEditorField
                        fieldName="content"
                        label="Mô tả"
                        required
                        requiredMsg="Nhập mô tả"
                    />
                </Col>

                <Col span={12}>
                    <Form.Item
                        name="thumbnailImage"
                        label="Ảnh đại diện"
                        required
                    >
                        <UploadImageField
                            accept={'.png,.jpeg,.jgp'}
                            fieldName="thumbnailImage"
                            required
                            requiredMsg="Vui lòng chọn ảnh"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="source"
                        label="Nguồn viết bài"
                        required
                        requiredMsg="Nhập nguồn viết bài"
                    />
                </Col>
            </Row>
        </Form>
    );
};

export default NewsForm;