import { ListPageContainer } from '@/components';
import { bannerActions } from '@/redux/actions';
import paths from '@/constants/paths';
import { Tag } from 'antd';

const ListPage = () => {

    return (
        <ListPageContainer
            disablePagination={true}
            objectName="banner"
            detailUrl={paths.bannerDetail}
            createUrl={paths.bannerCreate}
            breadcrumbs={[
                { breadcrumbName: 'Giới thiệu' },
                { breadcrumbName: 'Danh sách banner' }
            ]}
            columns={[
                { title: 'Id', dataIndex: 'id' },
                { title: 'Image', dataIndex: 'imageUrl', render: (imageUrl) => <><img src={imageUrl} alt='imgBanner' style={{ width: '500px', height: '250px', objectFit: 'cover' }} /></> },
                {
                    title: 'Status', dataIndex: 'status', align: 'center', render: (status) => {
                        if (status) {
                            return <Tag color="success">Active</Tag>
                        } else {
                            return <Tag color="warning">Inactive</Tag>
                        }
                    }
                },
            ]}
            actionBar={{
                isEdit: true,
                isCreate: true,
                isDelete: true
            }}
            getListAction={bannerActions.getList}
            deleteAction={bannerActions.delete}
        >
        </ListPageContainer>

    )
}

export default ListPage;