import { useParams } from 'react-router-dom';

import { SavePageContainer } from '@/components';
import NewsForm from './newsForm';

import paths from '@/constants/paths';
import { newsActions } from '@/redux/actions';
import dayjs from 'dayjs';

const SavePage = () => {
    const { id } = useParams();
    const isCreating = !id;

    const getBreadcrumbs = (data) => {
        return [
            { breadcrumbName: 'Tin tức' },
            { breadcrumbName: 'Danh sách tin tức', path: paths.newsList },
            { breadcrumbName: isCreating ? 'Thêm mới tin tức' : 'Cập nhật tin tức' }
        ];
    }
    const getDetailDataMapping = (response) => {
        return {
            ...response,
            dateCreated: dayjs(response.dateCreated) 
        }
    }
    return (
        <SavePageContainer
            form={NewsForm}
            isCreating={!id}
            objectName="tin tức"
            pageTitle={isCreating ? "Thêm mới tin tức" : "Cập nhật thông tin tin tức"}
            listUrl={paths.newsList}
            detailUrl={paths.newsDetail}
            createAction={newsActions.create}
            updateAction={newsActions.update}
            getDetailAction={newsActions.getDetail}
            getBreadcrumbs={getBreadcrumbs}
            getDetailDataMapping={getDetailDataMapping}
        />

    )
}

export default SavePage;