import { ListPageContainer } from '@/components';
import { documentActions } from '@/redux/actions';
import paths from '@/constants/paths';
import { formatDate } from '@/utils/date';

const ListPage = () => {

    return (
        <ListPageContainer
            disablePagination={true}
            objectName="tài liệu"
            detailUrl={paths.documentDetail}
            createUrl={paths.documentCreate}
            breadcrumbs={[
                { breadcrumbName: 'Tài liệu' },
                { breadcrumbName: 'Danh sách tài liệu' }
            ]}
            columns={[
                { title: 'Ảnh đại diện', dataIndex: 'image', render: (imageUrl) => <><img src={imageUrl} alt='imgBanner' style={{ width: '100px', height: '100px', objectFit: 'cover' }} /></> },
                { title: 'Tên tài liệu', dataIndex: 'name' },
                { title: 'Mô tả', dataIndex: 'description' },
                { title: 'Ngày upload', dataIndex: 'dateCreated', render: (dateCreated) => <>{formatDate(dateCreated)}</> },
            ]}
            actionBar={{
                isEdit: true,
                isCreate: true,
                isDelete: true
            }}
            getListAction={documentActions.getList}
            deleteAction={documentActions.delete}
        >
        </ListPageContainer>

    )
}

export default ListPage;