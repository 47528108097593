import { Form, Row, Col } from 'antd';


import { UploadImageField } from '@/components/Form';
import { AsyncDropdownField } from '@/components';

import { statusDDL } from '@/constants/masterData';

const BannerForm = ({
    form,
    formId,
    detailData,
    isCreating,
    onSubmit
}) => {
    const handleSubmit = (values) => {
        // console.log(values);
        onSubmit(values);
    }
    return (
        <Form
            id={formId}
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            initialValues={{ ...detailData, period: 0 }}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="imageUrl"
                        label="Banner Image"
                        required
                    >
                        <UploadImageField
                            accept={'.png,.jpeg,.jgp'}
                            fieldName="imageUrl"
                            name="imageUrl"
                            required
                            requiredMsg="Please choose a banner image"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <AsyncDropdownField
                        fieldName="status"
                        label="Status"
                        required
                        requiredMsg="Please choose an option"
                        placeholder="Choose status of this banner"
                        options={statusDDL}
                    />
                </Col>
            </Row>
        </Form>
    );
};

export default BannerForm;