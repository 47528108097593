import { Form, Row, Col, Input } from 'antd';


import { TextField, UploadImageField } from '@/components/Form';
import { AsyncDropdownField } from '@/components';

import { statusDDL } from '@/constants/masterData';

const VisionForm = ({
    form,
    formId,
    detailData,
    isCreating,
    onSubmit
}) => {
    const handleSubmit = (values) => {
        // console.log(values);
        onSubmit(values);
    }
    return (
        <Form
            id={formId}
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            initialValues={{ ...detailData, period: 0 }}
        >
            <Row gutter={16}>

                <Col span={12}>
                    <TextField
                        fieldName="author"
                        label="Người truyền thông điệp"
                        required
                        requiredMsg="Nhập tên người truyền thông điệp"
                        placeholder="Nhập tên người truyền thông điệp"
                    />
                </Col>
                <Col span={12}>
                    <TextField
                        fieldName="role"
                        label="Chức vụ"
                        required
                        requiredMsg="Nhập chức vụ"
                        placeholder="Nhập chức vụ"
                    />
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="content"
                        label="Nội dung"
                        rules={[
                            { required: true, message: 'Nhập nội dung' },
                        ]}
                    >
                        <Input.TextArea
                            name={'content'}
                            style={{
                                height: 120,
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="avatarImage"
                        label="Avatar Image"
                        required
                    >
                        <UploadImageField
                            accept={'.png,.jpeg,.jgp'}
                            fieldName="avatarImage"
                            name="avatarImage"
                            required
                            requiredMsg="Please choose a avatar image"
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default VisionForm;