import { useParams } from 'react-router-dom';

import { SavePageContainer } from '@/components';
import BannerForm from './BannerForm';

import paths from '@/constants/paths';
import { bannerActions } from '@/redux/actions';

const SavePage = () => {
    const { id } = useParams();
    const isCreating = !id;

    const getBreadcrumbs = (data) => {
        return [
            { breadcrumbName: 'Giới thiệu' },
            { breadcrumbName: 'Danh sách banner', path: paths.bannerList },
            { breadcrumbName: isCreating ? 'Thêm mới banner' : 'Cập nhật banner' }
        ];
    }
    return (
        <SavePageContainer
            form={BannerForm}
            isCreating={!id}
            objectName="banner"
            pageTitle={isCreating ? "Thêm mới banner" : "Cập nhật thông tin banner"}
            listUrl={paths.bannerList}
            detailUrl={paths.bannerDetail}
            createAction={bannerActions.create}
            updateAction={bannerActions.update}
            getDetailAction={bannerActions.getDetail}
            getBreadcrumbs={getBreadcrumbs}
        />

    )
}

export default SavePage;