import { ListPageContainer } from '@/components';
import { candidateActions } from '@/redux/actions';
import paths from '@/constants/paths';
import { formatDate } from '@/utils/date';

const ListPage = () => {

    return (
        <ListPageContainer
            disablePagination={true}
            objectName="ứng viên"
            detailUrl={paths.candidateDetail}
            breadcrumbs={[
                { breadcrumbName: 'Ứng viên' },
                { breadcrumbName: 'Danh sách ứng viên' }
            ]}
            columns={[
                { title: 'Stt', dataIndex: 'id' },
                { title: 'Họ và tên', dataIndex: 'fullName' },
                { title: 'Vị trí ứng tuyển', dataIndex: 'jobTitle' },
                { title: 'Ngày ứng tuyển', dataIndex: 'dateApplied', render: (dateApplied) => <>{formatDate(dateApplied)}</> },
                { title: 'Email', dataIndex: 'email' },
                { title: 'Tiêu đề', dataIndex: 'content' },
                {
                    title: 'Link CV ứng viên', dataIndex: 'cvLink', render: (imageUrl) =>
                        <>
                            <a href={`${imageUrl}`}>{imageUrl}</a>
                        </>
                },
            ]}
            actionBar={{
                isEdit: true,
                // isCreate: true,
                // isDelete: true
            }}
            getListAction={candidateActions.getList}
        // deleteAction={candidateActions.delete}
        >
        </ListPageContainer>

    )
}

export default ListPage;