import { Form, Row, Col, Input } from 'antd';


import { TextField, UploadImageField } from '@/components/Form';
import { AsyncDropdownField } from '@/components';

import { statusDDL } from '@/constants/masterData';

const CoreValueForm = ({
    form,
    formId,
    detailData,
    isCreating,
    onSubmit
}) => {
    const handleSubmit = (values) => {
        // console.log(values);
        onSubmit(values);
    }
    return (
        <Form
            id={formId}
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            initialValues={{ ...detailData, period: 0 }}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <TextField
                        fieldName="name"
                        label="Tên giá trị"
                        required
                        requiredMsg="Nhập tên giá trị"
                        placeholder="Nhập tên giá trị"
                    />
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="description"
                        label="Mô tả chi tiết"
                        rules={[
                            { required: true, message: 'Nhập mô tả chi tiết' },
                        ]}
                    >
                        <Input.TextArea
                            name={'description'}
                            style={{
                                height: 120,
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="image"
                        label="Hình ảnh giá trị cốt lõi"
                        required
                    >
                        <UploadImageField
                            accept={'.png,.jpeg,.jgp'}
                            fieldName="image"
                            name="image"
                            required
                            requiredMsg="Vui lòng chọn hình"
                        />
                    </Form.Item>
                </Col>

            </Row>
        </Form>
    );
};

export default CoreValueForm;