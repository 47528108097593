import { combineReducers } from 'redux';

import loading from './loading';
import account from './account';
import common from "./common";

const rootReducer = combineReducers({
    loading,
    account,
    common,
});
export default rootReducer;